.blur {
  /* @apply h-full fixed z-20 rounded-t-2xl;
  width: 130vw;

  position: fixed;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}

.blur:before {
  content: "";
  position: absolute;
  background: inherit;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;


  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px); */
}

/* --------------------------------------------------------------------------------------------- */

.contact {
  @apply w-full h-full top-0 fixed z-10;
  overflow: auto;

  overscroll-behavior-y: none;
}

.contact-card {
  @apply text-2xl text-center rounded-t-2xl w-full h-fit z-10 sm:mt-36 sm:block sm:m-auto;
  max-width: 600px;
  position: absolute;
  /* bottom: 0; */
  min-height: 100%;
  color: #fffef7;
  box-shadow: 0px 1px 30px 5px rgba(0, 0, 0, 0.9);
  background-color: #0d0d0d;
  left: 0;
  right: 0;
}

.link {
  @apply mb-5;
}

.link-title {
  @apply inline-block mb-10 mt-6 pt-2 bg-gray-100 rounded-2xl px-3 py-2 font-semibold text-gray-900;
  font-size: 3.5vh;
  /* font-family: "Terminal"; */
  font-family: "Perfect-DOS";
  box-shadow: 0px 6px 15px 3px rgba(127, 127, 127, 0.205);
  text-shadow: 1px 2px 4px rgba(7, 7, 7, 0.265);
}

.link-text {
  /* font-family: "Terminal"; */
  @apply ml-10 text-left mb-1;
  font-size: 4.4vh;
  /* font-family: "Wayfarers"; */
  /* font-family: "MesseDuesseldorf"; */
  font-family: "Perfect-DOS";
  /* font-family: "VT323", monospace; */
}

.section {
  gap: 1rem;
}
.section:before {
  @apply pr-7 mr-10 ml-9 mb-10;
  display: flex;
  content: "";
  border-bottom: 2px dashed #bbb;
  align-self: stretch;
  flex-direction: column;
}

.button-back {
  @apply inline-block mt-12 text-4xl bg-gray-100 rounded-full px-3 py-1 text-gray-900 mb-5;
  position: fixed;
  z-index: 100;
  text-align: center;
  bottom: 0;

  box-shadow: 0px 1px 25px 10px rgb(0, 0, 0);
}

@media only screen and (max-width: 600px) {
  .contact-container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}
