.footer {
  /* Add shadows to create the "card" effect */
  background-color: #0d0d0d;
  /* background-color: rgb(1, 4, 8); */
  margin-bottom: 12%;
  width: auto;
  z-index: 9999;

  font-family: "Perfect-DOS";


  /* transition: 0.2s; */
  padding: 15px;

  height: fit-content;
}
