.trasparent-image {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}

.trasparent-image:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-box-shadow: inset 0px 0px 2000px 25px rgba(255, 5, 5, 0.76);
  box-shadow: inset 0px 0px 200px rgba(160, 160, 160, 0.5);

  -webkit-backdrop-filter: saturate(180%) blur(33px);
  backdrop-filter: saturate(180%) blur(33px);
  margin: -20px;
}

.article {
  @apply w-full h-full top-0 fixed z-10;
  overflow: auto;
}

.article-conteiner {
  @apply text-2xl text-center rounded-t-2xl w-full z-10 sm:mt-36 sm:block sm:m-auto;
  max-width: 800px;
  position: absolute;
  min-height: 100%;
  max-height: fit-content;
  color: #fffef7;
  box-shadow: 0px 1px 30px 20px rgba(0, 0, 0, 0.9);
  background-color: #0d0d0d;
  left: 0;
  right: 0;
}

.article-image {
  @apply h-60 sm:rounded-t-3xl;

  width: 100%;
  object-position: 0px 50%;

  object-fit: cover;
}

.article-titolo {
  @apply text-4xl mt-5 mx-5 mb-5;
  /* font-family: "Atyp"; */
  font-weight: 200;
}
.article-titolo a {
  text-decoration: underline;
}

.article-paragrafo {
  @apply mx-10 text-lg;
  text-align: left;
}
.article-paragrafo a {
  text-decoration: underline;
}

.article-footer {
  @apply mx-8 text-base;
  text-align: left;
  margin-bottom: 20%;
}

.article-footer a {
  text-decoration: underline;
}

.divider {
  gap: 1rem;
}
.divider:before {
  @apply mt-4 mb-4 mx-8;

  display: flex;
  content: "";
  border-bottom: 2px dashed #bbb;
  align-self: stretch;
  flex-direction: column;
}
