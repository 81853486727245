/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,100&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen&display=swap");

@font-face {
  font-family: "Atyp";
  src: local("Atyp Medium"), local("Atyp-Medium"),
    url("../font/AtypDisplay-Medium.woff2") format("woff2");

  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Terminal";
  src: local("clacon2"), local("clacon2"),
    url("../font/clacon2.woff2") format("woff2");

  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Wayfarers";
  src: url("../font/WayfarersToyBoxRegular-gxxER.ttf") format("truetype");
}
@font-face {
  font-family: "MesseDuesseldorf";
  src: url("../font/MesseDuesseldorf.ttf") format("truetype");
}
@font-face {
  font-family: "Perfect-DOS";
  src: url("../font/Perfect DOS VGA 437.ttf") format("truetype");
}



@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* ------GENERIC------ */
* {
  box-sizing: border-box;
}

.Poppins {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
}

.big-text {
  font-size: 40px;
}

.normal-text {
  font-size: 20;
}

html {
  scroll-behavior: smooth;
}

body {
  /* background: url(backgound2.jpg) no-repeat; */
  background-color: #161616;
  /* Keep the inherited background full size. */ /*#191a1f #e65100*/
  color: #fffef7;
  /* background-size: cover; */
  /* font-family: "Ghotam", "Poppins", sans-serif; */
  font-family: "Oxygen", sans-serif;
  margin: 0;
  padding: 0;

  font-size: 1em;
  line-height: 1.2;
  /* background-attachment: fixed; */
}

a {
  color: #fffef7;
}

.center {
  text-align: center;
}

.container {
  width: 95%;
  max-width: 1220px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
/*--------------BACKGROUND-----------------*/
pre {
  /* position: absolute; */
  position: fixed;
  /* margin-left: 10px; */
  padding: 0;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  /* font-family: "Simple Console", inherit; */
  font-family:  monospace;
  
  /* font-family: monospace; */
  
}
.containerbackground {
  /* margin: 3rem; */

  /* top: 0;
  left: 0;
  bottom: 0; */
  z-index: -1;
  overflow: hidden;
  /* transform: rotate(300deg);
  -webkit-transform: rotate(300deg); */
  /* color: rgba(255, 234, 0, 0.8); */
  color: rgba(255, 255, 255, 0.525);
}
