/*--------------HEADER-----------------*/

/* LOGO GRANDE INIZIALE */

/* effetto glow logo */
.shadowfilter {
  -webkit-filter: drop-shadow(4px 2px 10px rgba(251, 253, 107, 0.5));
  filter: drop-shadow(4px 2px 10px rgba(251, 253, 107, 0.5));
}

.logo {
  @apply content-center;
  margin: auto;
}

/* CARD HOME PAGE */
.cardHeader {
  @apply mb-5 text-center m-auto;
  background-color: #0d0d0d;

  left: 0;
  right: 0;

  z-index: 9;
  top: 0;
  /* transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out; */
  transition: 0.1s;
  padding: 15px;

  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.9);
  border-radius: 0px 0px 2rem 2rem;
  height: fit-content;
}

.cardHeader-nome {
  @apply text-6xl text-center;
  font-family: "Poppins", sans-serif;
  /* font-family: "Atyp"; */
  font-weight: 400;
}

.cardHeader-citazione {
  @apply text-center;
}

.cardHeader-icon-container {
  @apply flex justify-center;
}

.cardHeader-icon {
  @apply text-center bg-gray-100 rounded-full mx-5 px-3 py-1 text-lg font-semibold text-gray-900 mr-2;
  margin: 10px;
  font-size: 20px;
}

.cardHeader-link {
  @apply inline-block bg-gray-100 rounded-full px-3 py-1 text-lg font-bold text-gray-900 mr-2;
  margin: 10px;
  font-size: 20px;
}

/* NAVBAR PICCOLA */

.navbar-container {
  @apply w-full right-0 left-0 bottom-0 rounded-t-2xl sm:bottom-auto sm:top-0 sm:rounded-b-2xl sm:rounded-t-none;
  background: #0d0d0d;
  position: fixed;
  z-index: 9;
  /* top: 0; */
  /* left: 0;
    right: 0;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.9);
    width: 100%;
    font-size: 20px; */
  /* margin-bottom: 20px; */

  /* ----- */
  /* bottom: 0;
    border-radius: 15px 15px 0px 0px; */
}

.navbar-content {
  display: flex;
  justify-content: space-between;
}

.navbar-text {
  @apply flex bg-gray-100 rounded-full px-3 py-1 text-2xl font-semibold text-gray-900 mr-2;
  margin: 10px;
  font-size: 20px;
}

/* NAVBAR schermi grandi */
.navbar-container-bg {
  @apply fixed top-0 h-14 m-auto;
  width: 100%;
  left: 0;
  right: 0;
  border-radius: 0px 0px 2rem 2rem;
  box-shadow: 0px 1px 20px 3px rgba(0, 0, 0, 0.9);
  background: #0d0d0d;
  z-index: 100;
}
.navbar-content-bg {
  @apply flex justify-start;
  height: 100%;
}

.navbar-nome-bg {
  @apply text-4xl mt-2 ml-6 pl-3;
  font-family: "Poppins", sans-serif;
  text-shadow: 2px 2px 6px rgba(185, 185, 185, 0.329);
}

.navbar-action-bg {
  @apply fixed bottom-0 right-7;
  box-shadow: 0px 6px 20px 3px rgba(10, 10, 10, 0.772);
  text-shadow: 1px 4px 4px rgba(7, 7, 7, 0.836);
}

.navbar-iconlink-bg {
  @apply flex bg-gray-100 rounded-full px-2 text-xl font-semibold text-center ml-4 my-3;

  box-shadow: 0px 6px 20px 3px rgba(127, 127, 127, 0.305);
  text-shadow: 1px 4px 4px rgba(7, 7, 7, 0.365);
}
