ul,
li {
  list-style: none;
  /* padding: 10px; */
  @apply mb-5;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

/* .card {
  position: relative;
  padding: 25px;
  height: 460px;
  flex: 0 0 40%;
  max-width: 40%;
}

.card:nth-child(4n + 1),
.card:nth-child(4n + 4) {
  flex: 0 0 60%;
  max-width: 60%;
} */
.card,
.card:nth-child(4n + 1),
.card:nth-child(4n + 4) {
  position: relative;
  /* padding: 25px; */
  height: 400px;
  /* flex: 1 0 100%; */
  max-width: 700px;
  /* padding: 10px; */
  margin-left: auto;
  margin-right: auto;

  padding-left: 0;
  padding-right: 0;
}
.card:nth-child(odd) {
  padding-left: 0;
}

.card:nth-child(even) {
  padding-right: 0;
}

.card-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  pointer-events: none;
}

.card-content-container.open {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  padding: 40px 0;
}

.card-content {
  pointer-events: auto;
  position: relative;
  border-radius: 30px;
  background: #1c1c1e;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.trasparent {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}

.trasparent:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5); */
  -webkit-box-shadow: inset 0px 0px 2000px 25px rgba(255, 5, 5, 0.76);
  box-shadow: inset 0px 0px 200px rgba(160, 160, 160, 0.5);

  -webkit-backdrop-filter: saturate(180%) blur(33px);
  backdrop-filter: saturate(180%) blur(33px);
  margin: -20px;
}

.open .card-content {
  height: auto;
  max-width: 700px;
  overflow: hidden;
  pointer-events: none;
}

.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-image {
}

.card-image-container {
  background: inherit;

  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 420px;
  width: 100vw;
}

.card-image-container:before {
  content: "";
  position: absolute;
  background: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(132, 132, 132, 0.5);
  -webkit-backdrop-filter: saturate(180%) blur(5px);
  backdrop-filter: saturate(180%) blur(5px);
}

.open .card-image-container,
.open .title-container {
  z-index: 1;
}

.title-container {
  @apply mx-5 py-5 place-content-center;
  /* position: absolute;
  top: 7%;
  left: 15px; */
  /* height: 200px; */
}

/* .open .title-container {
  top: 30px;
  left: 30px;
} */

.list-titolo {
  @apply mx-3 mt-3 text-center;
  color: #fffef7;
  position: relative;
  font-family: "Poppins", sans-serif;
  /* font-family: "Atyp"; */
  text-shadow: 2px 5px 5px rgba(0, 0, 0, 0.463),
    1px 1px 1px rgba(0, 0, 0, 0.5), 5px 7px 5px rgba(206, 89, 55, 0),
    11px 9px 5px rgba(31, 29, 29, 0.12);

  /* margin: 8px 0;
  font-size: 40px; */
  /* width: 70%;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  min-height: 100px; */
  /* background: red; */
  /* font-size: 20.5vw;
  text-overflow: scale; */
  /* display: block;
  word-wrap: break-word; */

  /* display: inline-block;
  white-space: nowrap; */
}

.category {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 990px;
}

.overlay a {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  left: 50%;

  transform: translateX(-50%);
}

.content-container {
  padding: 460px 35px 35px 35px;
  max-width: 700px;
  width: 90vw;
}

p {
  color: #9d9ca1;
  font-size: 20px;
  line-height: 28px;
}

@media only screen and (max-width: 800px) {
  /* .card {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 0 0 50%;
    max-width: 50%;
  } */
}

@media only screen and (max-width: 600px) {
  .card,
  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 1 0 100%;
    max-width: 85%;
    /* padding: 10px; */
    margin-left: auto;
    margin-right: auto;

    padding-left: 0;
    padding-right: 0;
  }

  /* posizionamento  su più righe*/

  /* .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 1 0 100%;
    max-width: 95%;
  }

  .card-content-container.open {
    padding: 0;
  } */
}
